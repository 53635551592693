<template>
  <div class="ProjectTasksListWrapper">
    <div class="ProjectTasksList">
      <div class="TasksListHeader">
        <p class="BlockHeader">Задачи</p>
        <el-checkbox v-model="checked3" label="Только невыполненные" />
      </div>
      <div class="TasksListContent">
        <TaskCard
          v-for="TaskBlock in TasksBlock"
          :key="TaskBlock"
          :TaskName="TaskBlock.blockName"
          :TaskBGColor="TaskBlock.bgColor"
          :TaskList="TaskBlock.blockTasks"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.ProjectTasksListWrapper {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background: rgba(32, 30, 72, 0.7);

  /* shadow */
  box-shadow: 8px 0px 8px rgba(31, 18, 35, 0.15);
  border-radius: 20px;
}
.ProjectTasksListWrapper > .ProjectTasksList {
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;

  padding: 22px 22px;
}
.ProjectTasksList > .TasksListHeader {
  position: relative;
  display: flex;
  flex-direction: row;

  margin-bottom: 18px;
}
.ProjectTasksList > .TasksListHeader > * {
  margin-top: auto;
  margin-bottom: auto;
}

.ProjectTasksList > .TasksListContent {
  position: relative;
  display: flex;
  flex-direction: row;
}

.TasksListContent > * {
  margin: 0% auto;
}

.ProjectTasksListWrapper > .ProjectTasksList > .TasksListHeader > .BlockHeader {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #e8e7ec;

  margin-left: 12px;
  margin-right: 50px;
}

.ProjectTasksListWrapper > .ProjectTasksList > .TasksListHeader > .el-checkbox {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;

  /* or 23px */
  text-decoration-line: underline;
  color: #e8e7ec;
}
</style>

<script>
import TaskCard from "@/components/Project/BetaTaskCard.vue";

export default {
  name: "TasksList",
  data() {
    return {
      TasksBlock: [
        {
          blockName: "Bitrix",
          bgColor: "rgba(255, 56, 212, 0.05)",
          blockTasks: [
            {
              status: "Waiting",
              tag: "Программирование",
              text: "Заполнить профиль",
              date: "3 декабря 2022",
            },
            {
              status: "Waiting",
              tag: "Программирование",
              text: "Записаться на консультацию к эксперту",
              date: "4 декабря 2022",
            },
            {
              status: "Waiting",
              tag: "Программирование",
              text: "Подготовить UI KIT по шаблону в Figma",
              date: "4 декабря 2022",
            },
            {
              status: "Done",
              tag: "Программирование",
              text: "Создание маркеров в профиле для когортной аналитики",
              date: "6 декабря 2022",
            },
            {
              status: "Done",
              tag: "Программирование",
              text:
                "Разработать сервис для удобного бронирования общих ресурсов: один фотограф на организацию, один кабинет в общем пользовании и т.д. Календарная форма для бронирования, уведомление всех участников и проверка на повторное бронирование - важные части системы.",
              date: "4 декабря 2022",
            },
          ],
        },
        {
          blockName: "Front-End",
          bgColor: "rgba(56, 162, 255, 0.05)",
          blockTasks: [
            {
              status: "Waiting",
              tag: "Программирование",
              text: "Заполнить профиль",
              date: "3 декабря 2022",
            },
            {
              status: "Waiting",
              tag: "Программирование",
              text: "Записаться на консультацию к эксперту",
              date: "4 декабря 2022",
            },
            {
              status: "Waiting",
              tag: "Программирование",
              text: "Подготовить UI KIT по шаблону в Figma",
              date: "4 декабря 2022",
            },
            {
              status: "Done",
              tag: "Программирование",
              text: "Создание маркеров в профиле для когортной аналитики",
              date: "6 декабря 2022",
            },
            {
              status: "Done",
              tag: "Программирование",
              text:
                "Разработать сервис для удобного бронирования общих ресурсов: один фотограф на организацию, один кабинет в общем пользовании и т.д. Календарная форма для бронирования, уведомление всех участников и проверка на повторное бронирование - важные части системы.",
              date: "4 декабря 2022",
            },
          ],
        },
        {
          blockName: "Дизайн",
          bgColor: "rgba(137, 255, 56, 0.05)",
          blockTasks: [
            {
              status: "Waiting",
              tag: "Программирование",
              text: "Заполнить профиль",
              date: "3 декабря 2022",
            },
            {
              status: "Waiting",
              tag: "Программирование",
              text: "Записаться на консультацию к эксперту",
              date: "4 декабря 2022",
            },
            {
              status: "Waiting",
              tag: "Программирование",
              text: "Подготовить UI KIT по шаблону в Figma",
              date: "4 декабря 2022",
            },
            {
              status: "Done",
              tag: "Программирование",
              text: "Создание маркеров в профиле для когортной аналитики",
              date: "6 декабря 2022",
            },
            {
              status: "Done",
              tag: "Программирование",
              text:
                "Разработать сервис для удобного бронирования общих ресурсов: один фотограф на организацию, один кабинет в общем пользовании и т.д. Календарная форма для бронирования, уведомление всех участников и проверка на повторное бронирование - важные части системы.",
              date: "4 декабря 2022",
            },
          ],
        },
        {
          blockName: "Администрирование",
          bgColor: "rgba(156, 56, 255, 0.05)",
          blockTasks: [
            {
              status: "Waiting",
              tag: "Программирование",
              text: "Заполнить профиль",
              date: "3 декабря 2022",
            },
            {
              status: "Waiting",
              tag: "Программирование",
              text: "Записаться на консультацию к эксперту",
              date: "4 декабря 2022",
            },
            {
              status: "Waiting",
              tag: "Программирование",
              text: "Подготовить UI KIT по шаблону в Figma",
              date: "4 декабря 2022",
            },
            {
              status: "Done",
              tag: "Программирование",
              text: "Создание маркеров в профиле для когортной аналитики",
              date: "6 декабря 2022",
            },
            {
              status: "Done",
              tag: "Программирование",
              text:
                "Разработать сервис для удобного бронирования общих ресурсов: один фотограф на организацию, один кабинет в общем пользовании и т.д. Календарная форма для бронирования, уведомление всех участников и проверка на повторное бронирование - важные части системы.",
              date: "4 декабря 2022",
            },
          ],
        },
      ],
    };
  },
  components: { TaskCard },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
