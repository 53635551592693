<template>
  <div id="ProjectTeamListWrapper">
    <div class="ProjectTeamList">
      <p class="TeamListHeader">Команда</p>
      <div class="TeamList">
        <div v-for="Member in TeamList" :key="Member" class="TeamMember">
          <span class="MemberName">{{ Member.fio }}</span>
          <span class="AvatarIcon"><i class="bx bxs-user-circle"></i></span>
          <span class="TelegramIcon"><i class="bx bxl-telegram"></i></span>
          <span class="MemberAction">Исключить</span>
        </div>
      </div>
      <span class="TeamListFooter"></span>
    </div>
  </div>
</template>

<style scoped>
.TeamMember > .MemberName {
  position: relative;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  margin-right: auto;

  flex-shrink: 1;

  color: #c8c3de;
}

.TeamMember > .TelegramIcon,
.TeamMember > .AvatarIcon {
  position: relative;
  display: inline-flex;

  width: fit-content;
  height: fit-content;

  font-size: 20px;

  flex-shrink: 0;

  margin-left: 20px;

  color: #c8c3de;
}

.TeamMember > .MemberAction {
  position: relative;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  flex-shrink: 0;

  margin-left: 18px;

  color: #c8c3de;
}

#ProjectTeamListWrapper {
  position: relative;
  width: 100%;
  height: auto;

  padding: 28px 32px;

  overflow-y: auto;

  background: rgba(32, 30, 72, 0.7);

  /* shadow */
  box-shadow: 8px 0px 8px rgba(31, 18, 35, 0.15);
  border-radius: 20px;
}
#ProjectTeamListWrapper > .ProjectTeamList {
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.ProjectTeamList > .TeamListHeader {
  position: relative;
  margin-bottom: 16px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  /* students/white */
  color: #e8e7ec;
}
.ProjectTeamList > .TeamList {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  overflow-y: scroll;
}
.ProjectTeamList > .TeamListFooter {
  position: relative;
  width: auto;

  margin: 16px auto 0% 0%;
  padding-bottom: 1px;

  border-bottom: 2px solid rgba(232, 231, 236, 0.15);

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  /* students/white */
  color: #e8e7ec;
}

.TeamMember {
  position: relative;
  display: flex;
  flex-direction: row;

  width: 100%;
  height: auto;
}

.TeamMember > * {
  margin-top: auto;
  margin-bottom: auto;
}

.TeamList > .TeamMember:not(:nth-last-child(1)) {
  /* border-bottom: 1px solid rgba(232, 231, 236, 0.1); */
  margin-bottom: 20px;
}
</style>

<script>
export default {
  name: "BetaProjectTeamList",
  props: {
    TeamList: Array,
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  mounted() {},
  methods: {
    cutLongText(Text) {
      if (Text == null) {
        return "";
      }

      if (Text.length >= 100) {
        return `${Text.substr(0, 97)}...`;
      } else {
        return Text;
      }
    },
  },
};
</script>
