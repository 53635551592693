<template>
  <div id="TaskCardWrapper" :style="setBGColor">
    <div class="TaskCard">
      <p class="TaskCardTitle">{{ TaskName }}</p>
      <div class="TasksList">
        <div v-for="Task in TaskList" :key="Task" class="TaskBlock">
          <span class="ComboSpanLeftAlign TaskHeader">
            <span :class="`TaskStatus ${getStatusIconClass(Task.status)}`" />
            <span class="TaskStatusText">{{ getStatusText(Task.status) }}</span>
          </span>
          <p class="TasksBlockText">{{ cutLongText(Task.text) }}</p>
          <span class="ComboSpanLeftAlign TaskExecuter">
            <span class="TaskBlockDate">Исполнитель: Гаврилова Милана</span>
          </span>
          <span class="ComboSpanLeftAlign TaskFooter">
            <span class="TaskBadge">{{ Task.tag }}</span>
            <span class="TaskBlockDate">{{ Task.date }}</span>
          </span>
        </div>
      </div>
      <span class="TasksFooterText"></span>
    </div>
  </div>
</template>

<style scoped>
#TaskCardWrapper {
  position: relative;
  width: 24%;
  height: calc(100vh - 46px);

  padding: 28px 32px;

  overflow-y: auto;

  box-shadow: 8px 0px 8px rgba(31, 18, 35, 0.15);
  border-radius: 20px;
}
#TaskCardWrapper > .TaskCard {
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.TaskCard > .TaskCardTitle {
  position: relative;
  margin-bottom: 16px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  /* students/white */
  color: #e8e7ec;
}
.TaskCard > .TasksList {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  overflow-y: scroll;
}
.TaskCard > .TasksFooterText {
  position: relative;
  width: auto;

  margin: 16px auto 0% 0%;
  padding-bottom: 1px;

  border-bottom: 2px solid rgba(232, 231, 236, 0.15);

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  /* students/white */
  color: #c4c4c4;
}

.TaskBlock {
  position: relative;
  width: 100%;
  height: auto;
}

.TaskBlock > .TasksBlockText {
  margin-bottom: 12px;

  height: 70px;

  /* text medium */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 155%;

  /* or 23px */

  /* students/white */
  color: #e8e7ec;
}

.TasksList > .TaskBlock:not(:nth-child(1)) {
  margin-top: 16px;
}
.TasksList > .TaskBlock:not(:nth-last-child(1)) {
  border-bottom: 1px solid rgba(232, 231, 236, 0.2);
}

.ComboSpanLeftAlign {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.ComboSpanLeftAlign > * {
  margin-top: auto;
  margin-bottom: auto;
}

.ComboSpanLeftAlign > *:nth-child(1) {
  margin-right: 8px;
}

.TaskStatus {
  position: relative;
  width: 16px;
  height: 16px;
}

.TaskStatusText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;

  /* or 23px */

  /* students/white */
  color: #e8e7ec;
}

.TaskHeader {
  margin-bottom: 8px;
}

.StatusReady {
  background-image: url("./../../assets/img/profile/ProfileTaskIcons/TaskDone.svg");
  background-repeat: no-repeat;
  background-size: 16px;
}
.StatusFail {
  background-image: url("./../../assets/img/profile/ProfileTaskIcons/TaskFail.svg");
  background-repeat: no-repeat;
  background-size: 16px;
}

.TaskBlockDate {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;

  /* or 23px */

  /* students/white 2 */
  color: #c8c3de;
}

.TaskBadge {
  margin-right: 8px;

  padding: 4px 8px;

  width: 156px;
  height: 24px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  /* identical to box height */

  /* students/bg 2 */
  color: #201e48;
  background: #1d9bd1;
  border-radius: 4px;
}

.TaskFooter {
  margin-bottom: 16px;
}

.TaskExecuter {
  margin-bottom: 12px;
}
</style>

<script>
export default {
  name: "TaskCard",
  props: {
    TaskName: String,
    TaskBGColor: String,
    TaskList: Array,
  },
  data() {
    return {};
  },
  components: {},
  computed: {
    setBGColor() {
      if (this.TaskBGColor != null && this.TaskBGColor != "") {
        return `background: ${this.TaskBGColor};`;
      } else {
        return `background: rgba(32, 30, 72, 0.7);`;
      }
    },
  },
  mounted() {},
  methods: {
    cutLongText(Text) {
      if (Text == null) {
        return "";
      }

      if (Text.length >= 100) {
        return `${Text.substr(0, 97)}...`;
      } else {
        return Text;
      }
    },

    getStatusIconClass(Status) {
      if (Status == null) {
        return "";
      }

      switch (Status) {
        case "Waiting": {
          return "StatusFail";
        }
        case "Done": {
          return "StatusReady";
        }
        default: {
          return "";
        }
      }
    },
    getStatusText(Status) {
      if (Status == null) {
        return "";
      }

      switch (Status) {
        case "Waiting": {
          return "Не выполнена";
        }
        case "Done": {
          return "Выполнена";
        }
        default: {
          return "";
        }
      }
    },
  },
};
</script>
